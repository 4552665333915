export default function AdjustableHeight(Splide, Components, options) {

    // Useful elements
    const track = Components.Elements.track;
    const list = Components.Elements.list;

    // Custom options or using defaults
    const defaults = {
        "whileSliding": true,
        "speed": "0.4s",
    };

    const settings = defaults;
    const custom = options.adjustableHeight;

    if (custom) {
        settings.whileSliding = custom.whileSliding ?? defaults.whileSliding;
        settings.speed = custom.speed ?? defaults.speed;
    }

    function mount() {
        const eventType = settings.whileSliding ? "move active resize" : "active resized";
        adjustHeight();
        Splide.on(eventType, adjustHeight);
    }

    function adjustHeight() {
        const element = settings.whileSliding ? track : list;
        const slideHeight = Components.Slides.getAt(Splide.index).slide.offsetHeight;

        // If changing track height, add additional padding on the track element to the total height
        const trackStyle = track.currentStyle || window.getComputedStyle(track);
        const trackPadding = parseInt(trackStyle.paddingTop) + parseInt(trackStyle.paddingBottom);
        const totalHeight = (settings.whileSliding) ? slideHeight + trackPadding : slideHeight;

        // Let flex items have individual heights
        list.style.alignItems = "flex-start";

        // Set transition and height
        element.style.transition = "height " + settings.speed;
        element.style.height = totalHeight + "px";
    }

    return {
        mount,
    };
}
